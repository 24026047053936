import {
  Component,
  HostBinding,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AbEntrySearchService } from '@maximizer/core/global-search/data-access';
import {
  CountryListItem,
  LocaleService,
  SessionStorageService,
} from '@maximizer/core/shared/data-access';
import { CDN_URL, ListItem, Octopus } from '@maximizer/core/shared/domain';
import {
  FormGroupWrapper,
  FormWrapperBuilder,
} from '@maximizer/core/shared/ui';
import {
  AbEntryService,
  OutlookService,
} from '@maximizer/outlook/shared/data-access';
import {
  OutlookAbEntryDetails,
  OutlookAbEntryForm,
} from '@maximizer/outlook/shared/domain';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import { capitalize } from '@maximizer/shared/util';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { catchError, finalize, forkJoin } from 'rxjs';

@Component({
  selector: 'maximizer-add-abentry',
  templateUrl: './add-abentry.component.html',
})
export class AddAbEntryComponent implements OnInit {
  @HostBinding('class.max-outlook') hostClass = true;
  constructor(
    @Inject(CDN_URL) public cdn: string,
    private abentryService: AbEntryService,
    private localService: LocaleService,
    private abEntrySearchService: AbEntrySearchService,
    private route: ActivatedRoute,
    private router: Router,
    private storage: SessionStorageService,
    private outlookService: OutlookService,
  ) {}

  @Input() type = '';
  capitalizedType = '';

  canSave = false;
  loading = true;

  abEntryList?: ListItem<string>[];
  filteredAbEntryList: ListItem<string>[] = [];

  abentry: OutlookAbEntryDetails | null = null;
  phones: { type: string; value: string }[] = [];
  emails: { type: string; value: string }[] = [];

  emailOptions: string[] = [];
  phoneOptions: string[] = [];
  phoneLimit = 4;
  emailLimit = 3;
  form?: FormGroupWrapper<OutlookAbEntryForm>;
  countries?: CountryListItem[];

  /* Contact - Company auto complete */
  autocompleteLoading = false;
  @ViewChild('autoComplete')
  autoComplete!: AutoCompleteComponent;
  createCompanyLoading = false;

  previousCompanyAutocompleteInput = '';
  companyNameSearch = '';
  companySelected: ListItem<string> | null = null;

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  @ViewChild('companyNotification')
  companyNotification!: OutlookNotificationComponent;

  private readonly newPartitionKey = 'new-item';

  ngOnInit(): void {
    this.capitalizedType = capitalize(this.type);

    this.loadData();
    if (this.type == 'individual') this.canSave = true;
  }

  private loadData(): void {
    forkJoin({
      countries: this.localService.getCountries(),
      fieldOptions: this.abentryService.getFieldOption(),
    })
      .pipe(
        catchError(async () => {
          this.notification.show('load', 'error');
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe((data) => {
        if (!data) return;

        if (data.countries) this.countries = data.countries;
        this.phoneOptions =
          data.fieldOptions.phoneDescriptions?.map((o) => o.id ?? '') ?? [];
        this.emailOptions =
          data.fieldOptions.emailDescriptions?.map((o) => o.id ?? '') ?? [];
        this.setForm();

        const company = this.form?.group.get('company');
        if (company) company.setErrors(null);
      });
  }

  private setForm(): void {
    try {
      const email = this.route.snapshot.queryParamMap.get('email') ?? '';
      const name = this.route.snapshot.queryParamMap.get('name');
      const fullName = name?.split(' ') ?? ['', email ?? ''];
      let firstName = '';
      let lastName = '';
      if (fullName.length == 1) lastName = fullName[0];
      else {
        firstName = fullName[0] ?? '';
        lastName = fullName[1] ?? '';
      }

      this.phones.push({ type: '', value: '' });
      this.emails.push({ type: '', value: email });

      const isAddressDisabled = this.type == 'contact';

      this.form = FormWrapperBuilder.group<OutlookAbEntryForm>(
        {
          key: null,
          firstName: [firstName, [Validators.maxLength(79)]],
          lastName: [lastName, [Validators.maxLength(79)]],

          email1: [this.emails[0]?.value, []],
          email1Description: [null, [Validators.maxLength(15)]],
          email2: [null, [Validators.maxLength(255), Validators.email]],
          email2Description: [null, [Validators.maxLength(15)]],
          email3: [
            null,
            {
              validators: [Validators.maxLength(255), Validators.email],
              updateOn: 'blur',              
            },
          ],
          email3Description: [null, [Validators.maxLength(15)]],

          phone1: [
            null,
            [Validators.maxLength(21), Validators.pattern(/^[\d\s().\-_]*$/)],
          ],
          phone1Description: [null, [Validators.maxLength(21)]],
          phone2: [
            null,
            [Validators.maxLength(21), Validators.pattern(/^[\d\s().\-_]*$/)],
          ],
          phone2Description: [null, [Validators.maxLength(21)]],
          phone3: [
            null,
            [Validators.maxLength(21), Validators.pattern(/^[\d\s().\-_]*$/)],
          ],
          phone3Description: [null, [Validators.maxLength(21)]],
          phone4: [
            null,
            [Validators.maxLength(21), Validators.pattern(/^[\d\s().\-_]*$/)],
          ],
          phone4Description: [null, [Validators.maxLength(21)]],

          website: [null, [Validators.maxLength(255)]],
          company: [null, [Validators.maxLength(79)]],
          position: [null, [Validators.maxLength(79)]],
          
          address1: [{value: null, disabled: isAddressDisabled}, [Validators.maxLength(79)]],
          address2: [{value: null, disabled: isAddressDisabled}, [Validators.maxLength(79)]],
          city: [{value: null, disabled: isAddressDisabled}, [Validators.maxLength(79)]],
          province: [{value: null, disabled: isAddressDisabled}, [Validators.maxLength(79)]],
          postalCode: [{value: null, disabled: isAddressDisabled}, [Validators.maxLength(79)]],
          country: [{value: null, disabled: isAddressDisabled}, [Validators.maxLength(79)]],
          
          addressKey: [null],
          parentKey: [
            null,
            this.type == 'individual' ? [] : [Validators.required],
          ],
          type: this.type,
        },

        this.save,
        () => {
          this.form?.control.setErrors(null);
          if (this.form?.valid) {
            this.canSave = true;
          } else {
            this.canSave = false;
          }
        },
      );
    } catch (error) {
      console.error(error);
    }
  }

  save() {
    if (!this.form) {
      return;
    }
    const validation = this.form.validate();
    if (!validation.valid) return;

    this.loading = true;
    this.autocompleteLoading = true;

    this.abentryService
      .save(this.form.value)
      .pipe(
        catchError(() => {
          this.notification.show('create', 'error');
          return '';
        }),
        finalize(() => {
          this.loading = false;
          this.autocompleteLoading = false;
        }),
      )
      .subscribe((result) => {
        if (result == '') return;

        const existing = this.storage.get(
          this.outlookService.storagePartitionKey + this.newPartitionKey,
        );
        let entryArray = [];
        if (existing) {
          entryArray = JSON.parse(existing);
        }
        if (this.form) {
          entryArray.push(this.form.value.email1);
          this.storage.set(
            this.outlookService.storagePartitionKey + this.newPartitionKey,
            JSON.stringify(entryArray),
          );
        }

        this.notification.show('create', 'success');
        this.router.navigate(['../']);
      });
  }

  cancel(): void {
    this.router.navigate(['../']);
  }

  /* Start: Phone and email entry methods*/
  onEntryChange(event: string, prop: string, index: number) {
    const array = prop.includes('email') ? this.emails : this.phones;
    if (prop.includes('Description')) {
      array[index].type = event.trim();
    } else {
      array[index].value = event.trim();
    }
  }

  onRemoveEntry(i: number, prop: string) {
    if (prop.includes('email')) {
      this.emails.splice(i, 1);
      const array = JSON.parse(JSON.stringify(this.emails)); // update form reference
      this.emails = array;
      this.updateEmails();
    } else {
      const array = JSON.parse(JSON.stringify(this.phones)); // update form reference
      array.splice(i, 1);
      this.phones = array;
      this.updatePhones();
    }
  }

  onAddEntry(type: string) {
    if (type.includes('email')) {
      this.emails.push({ type: '', value: '' });
    } else {
      this.phones.push({
        type: '',
        value: '',
      });
    }
  }

  getFieldLimit(prop: string) {
    if (prop.includes('email')) return this.emailLimit;
    return this.phoneLimit;
  }

  private updateEmails() {
    this.form?.group.patchValue({
      email1: this.emails[0]?.value.trim() ?? '',
      email1Description: this.emails[0]?.type.trim() ?? '',

      email2: this.emails[1]?.value.trim() ?? '',
      email2Description: this.emails[1]?.type.trim() ?? '',

      email3: this.emails[2]?.value.trim() ?? '',
      email3Description: this.emails[2]?.type.trim() ?? '',
    });
    const company = this.form?.group.get('company');
    if (company) company.setErrors(null);
  }

  private updatePhones() {
    this.form?.patch({
      phone1: this.phones[0]?.value.trim() ?? '',
      phone1Description: this.phones[0]?.type.trim() ?? '',
      phone2: this.phones[1]?.value.trim() ?? '',
      phone2Description: this.phones[1]?.type.trim() ?? '',
      phone3: this.phones[2]?.value.trim() ?? '',
      phone3Description: this.phones[2]?.type.trim() ?? '',
      phone4: this.phones[3]?.value.trim() ?? '',
      phone4Description: this.phones[3]?.type.trim() ?? '',
    });
    const company = this.form?.group.get('company');
    if (company) company.setErrors(null);
  }
  /* End: Phone and email entry methods*/

  /* Start: Company auto complete methods*/
  handleCompanyValueChange(inputText: string) {
    if (inputText == '') {
      this.companyNameSearch = '';
      this.companySelected = null;
      this.form?.patch({
        parentKey: null,
        company: '',
      });
      this.validateCompanyField();
      return;
    }

    let trimmedInput = inputText;
    if (inputText.length > 80) {
      trimmedInput = inputText.substring(0, 80);
      this.form?.patch({
        company: trimmedInput,
      });
      this.validateCompanyField();
      return;
    }

    this.companyNameSearch = trimmedInput;
    const contactData = this.filteredAbEntryList.find(
      (o) => o.name == trimmedInput,
    );

    if (contactData) {
      this.companySelected = contactData;
      this.form?.patch({
        parentKey: contactData.id,
        company: contactData.name,
      });
    } else if (
      trimmedInput != this.companySelected?.name &&
      this.companySelected?.id
    ) {
      this.form?.patch({
        parentKey: this.companySelected?.id,
        company: this.companySelected?.name,
      });
    }
    this.loadCompanyAddress();
    this.validateCompanyField();
  }
  handleCompanyBlur() {
    this.validateCompanyField();
  }

  openAutocompletePopup() {
    if (
      this.companyNameSearch.length < 3 ||
      this.companySelected?.name == this.companyNameSearch
    ) {
      this.autoComplete.toggle(false);
    } else {
      this.autoComplete.toggle();
    }
  }

  handleCompanyFilterChange(inputText: string) {
    this.companyNameSearch = inputText.trim();
    if (inputText.length < 3) {
      this.autocompleteLoading = false;
      this.autoComplete.toggle(false);
      return;
    }

    if (inputText.length > 80) {
      this.companyNameSearch = inputText.trim().substring(0, 80);
      if (this.previousCompanyAutocompleteInput == this.companyNameSearch)
        return;
    }

    this.validateCompanyField();
    this.autocompleteLoading = true;
    const previous = this.companyNameSearch.toString();
    this.previousCompanyAutocompleteInput = previous;

    setTimeout(() => {
      if (this.companyNameSearch != previous) return;
      this.filteredAbEntryList = [];
      this.abEntryList = [];
      this.loadCompanyData();
    }, 1000);
  }

  loadCompanyData() {
    this.abEntrySearchService
      .search(this.companyNameSearch, true, 15, Octopus.AbEntryType.Company)
      .pipe(
        catchError(async () => {
          this.companyNotification.show('load', 'error');
        }),
        finalize(() => (this.autocompleteLoading = false)),
      )
      .subscribe((data) => {
        if (!data) return;
        if (data.length > 0) {
          this.filteredAbEntryList = data.map((ab) => ({
            id: ab.key ?? '',
            name: ab.companyName,
          }));
        }

        this.abEntryList = this.filteredAbEntryList.filter(
          (s) =>
            s.name
              .toLowerCase()
              .indexOf(this.companyNameSearch.toLowerCase()) !== -1,
        );
      });
  }

  createCompany() {
    const companyName = this.companyNameSearch.toString();
    this.createCompanyLoading = true;
    this.abentryService
      .saveCompany(companyName)
      .pipe(
        catchError(() => {
          this.companyNotification.show('create', 'error');
          return '';
        }),
        finalize(() => {
          this.createCompanyLoading = false;
          this.loading = false;
        }),
      )
      .subscribe((result) => {
        if (result == '') return;

        this.form?.patch({
          parentKey: result ?? '',
          company: companyName,
        });
        this.clearCompanyAddress();

        this.autoComplete.toggle(false);
        this.validateCompanyField();
        this.companyNotification.show('create', 'success');
      });
  }

  companyValidatorSet = false;
  validateCompanyField() {
    const company = this.form?.group.get('company');
    if (!company) return;

    if (company && !this.companyValidatorSet) {
      company.setValidators(Validators.required);
    }

    if (this.companyNameSearch.length > 79) {
      company.setErrors({ maxlength: 79 });
    } else if (this.companyNameSearch == '') {
      company.setErrors({ required: true });
      this.clearCompanyAddress();
    } else if (
      this.companyNameSearch.length > 0 &&
      (this.form?.value.parentKey ?? '') == ''
    ) {
      company.setErrors({ exist: true });
    } else {
      company.setErrors(null);
    }
  }


  loadCompanyAddress(){
    if(!this.companySelected?.id) return;
    if(this.type !== 'contact') return;

    this.autocompleteLoading = true;

    this.abentryService.getAllDetailsByKey(this.companySelected.id)
      .pipe(
        catchError(async () => {
          this.companyNotification.show('load', 'error');
        }),
        finalize(() => (this.autocompleteLoading = false)),
      )
      .subscribe((data) => {
        if (!data) return;
        this.form?.patch({
          address1: data.address?.address1,
          address2: data.address?.address2,
          country: data.address?.country,
          city: data.address?.city,
          province: data.address?.provinceState,
          postalCode: data.address?.zipcode        
        })

      });
  }

  private clearCompanyAddress(){

    this.form?.patch({
      address1: "",
      address2: "",
      country: undefined,
      city: "",
      province: "",
      postalCode: "" 
    })
  }
}
