import { NgModule } from '@angular/core';
import { EllipsisTooltipDirective } from './ellipsis-tooltip.directive';
import { TemplateDirective } from './template.directive';
import { ScrollEventDirective } from './scroll-event.directive';
import { KeyEventDirective } from './key-event.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { KendoPopupAutoResizeDirective } from './kendo-popup-auto-resize.directive';
import { PhoneFormatterDirective } from './phone-format.directive';
@NgModule({
  exports: [
    EllipsisTooltipDirective,
    TemplateDirective,
    ScrollEventDirective,
    KeyEventDirective,
    ClickOutsideDirective,
    PhoneFormatterDirective,
    KendoPopupAutoResizeDirective,
  ],
  declarations: [
    EllipsisTooltipDirective,
    TemplateDirective,
    ScrollEventDirective,
    KeyEventDirective,
    ClickOutsideDirective,
    PhoneFormatterDirective,
    KendoPopupAutoResizeDirective,
  ],
})
export class UiDirectivesModule {}
