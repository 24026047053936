<maximizer-outlook-menu
  [pageTitle]="
    'outlook.abentry.add-title' | translate: { type: capitalizedType }
  "
  [hideRefresh]="true"
  [backRoutePath]="'/home'"
></maximizer-outlook-menu>
<div class="flex flex-col h-full scrollbar">
  <maximizer-loader *ngIf="loading" type="legacy"></maximizer-loader>
  <form *ngIf="form" class="flex flex-col p-4 text-xs" [formGroup]="form.group">
    <kendo-formfield>
      <kendo-label
        [for]="firstName"
        [text]="'outlook.abentry.firstName' | translate"
      ></kendo-label>
      <kendo-textbox
        [maxlength]="80"
        #firstName
        formControlName="firstName"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.firstName.errors"
        [fieldTranslation]="'outlook.abentry.firstName'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>

    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="lastName"
        [text]="'outlook.abentry.lastName' | translate"
        >*</kendo-label
      >
      <kendo-textbox
        [maxlength]="80"
        required
        #lastName
        formControlName="lastName"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        *ngIf="form.controls.lastName.touched"
        [errorObject]="form.controls.lastName.errors"
        [fieldTranslation]="'outlook.abentry.lastName'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>

    <kendo-label
      class="mt-2"
      [text]="'outlook.abentry.email' | translate"
    ></kendo-label>

    <ng-container *ngFor="let email of emails; let index = index">
      <ng-container
        *ngTemplateOutlet="
          templateRef;
          context: {
            $implicit: emails,
            index: index,
            prop: 'email' + (index + 1),
          }
        "
      ></ng-container>
    </ng-container>

    <kendo-label
      class="mt-2"
      [text]="'outlook.abentry.phone' | translate"
    ></kendo-label>
    <ng-container *ngFor="let phone of phones; let index = index">
      <ng-container
        *ngTemplateOutlet="
          templateRef;
          context: {
            $implicit: phones,
            index: index,
            prop: 'phone' + (index + 1),
          }
        "
      ></ng-container>
    </ng-container>

    
    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="position"
        [text]="'outlook.abentry.position' | translate"
      ></kendo-label>
      <kendo-textbox
        [maxlength]="80"
        #position
        formControlName="position"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.position.errors"
        [fieldTranslation]="'outlook.abentry.position'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>

    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="website"
        [text]="'outlook.abentry.website' | translate"
      ></kendo-label>
      <kendo-textbox
        [maxlength]="256"
        #website
        formControlName="website"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.website.errors"
        [fieldTranslation]="'outlook.abentry.website'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>
    <kendo-formfield class="mt-2">
      <kendo-label
        for="company"
        [text]="'outlook.abentry.company' | translate"
        >{{ type === 'individual' ? '' : '*' }}</kendo-label
      >
      <kendo-textbox
        *ngIf="type === 'individual'; else companySearchInput"
        #company
        formControlName="company"
        [maxlength]="80"
      ></kendo-textbox>

      <ng-template #companySearchInput>
        <kendo-autocomplete
          #autoComplete
          [suggest]="false"
          [filterable]="true"
          (filterChange)="handleCompanyFilterChange($event)"
          (valueChange)="handleCompanyValueChange($event)"
          (blur)="handleCompanyBlur()"
          [data]="abEntryList"
          (opened)="(false)"
          formControlName="company"
          [loading]="autocompleteLoading"
          valueField="name"
          [highlightFirst]="false"
          [maxlength]="80"
          [placeholder]="'outlook.forms.searchInMaximizer' | translate"
        >
          <ng-template kendoAutoCompleteNoDataTemplate>
            <maximizer-loader
              type="legacy"
              *ngIf="autocompleteLoading"
            ></maximizer-loader>
            <div
              class="flex flex-col text-left mx-2"
              *ngIf="!autocompleteLoading"
            >
              <maximizer-loader
                type="legacy"
                *ngIf="createCompanyLoading"
              ></maximizer-loader>
              <span class="mb-4 text-left text-body-2 break-all">{{
                'outlook.abentry.company-notfound'
                  | translate: { company: companyNameSearch }
              }}</span>
              <kendo-button
                [disabled]="createCompanyLoading"
                (click)="createCompany()"
                theme="secondary"
                class="min-w-3/5 mx-auto"
                >{{
                  'outlook.abentry.company-create' | translate
                }}</kendo-button
              >
            </div>
          </ng-template>
        </kendo-autocomplete>
      </ng-template>

      <maximizer-outlook-form-error
        [errorObject]="form.controls.company.errors"
        [fieldTranslation]="'outlook.abentry.company'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>
    <div class="mt-4" *ngIf="type === 'contact'">
      {{ 'outlook.abentry.companyAddress' | translate }}
      <span
        kendoTooltip
        class="fa-icons icon-solid icon-12 icon-circle-question text-neutral-90"
        title="{{ 'outlook.abentry.companyAddressTooltip' | translate }}"
        [tooltipWidth]="250"
        [tooltipTemplate]="tooltip"
        [position]="'bottom'"
        tooltipClass="ml-8"
      ></span>
      <ng-template #tooltip>
        <p class="text-body">
          {{ 'outlook.abentry.companyAddressTooltip' | translate }}
        </p>
      </ng-template>
    </div>

    
    <kendo-formfield class="mt-4">
      <kendo-label
        [for]="address1"
        [text]="'outlook.abentry.address1' | translate"
      ></kendo-label>
      <kendo-textbox
        [maxlength]="80"
        #address1
        formControlName="address1"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.address1.errors"
        [fieldTranslation]="'outlook.abentry.address1'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>
    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="address2"
        [text]="'outlook.abentry.address2' | translate"
      ></kendo-label>
      <kendo-textbox
        [maxlength]="80"
        #address2
        formControlName="address2"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.address2.errors"
        [fieldTranslation]="'outlook.abentry.address2'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>
    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="city"
        [text]="'outlook.abentry.city' | translate"
      ></kendo-label>
      <kendo-textbox
        [maxlength]="80"
        #city
        formControlName="city"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.city.errors"
        [fieldTranslation]="'outlook.abentry.city'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>
    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="province"
        [text]="'outlook.abentry.province' | translate"
      ></kendo-label>
      <kendo-textbox
        [maxlength]="80"
        #province
        formControlName="province"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.province.errors"
        [fieldTranslation]="'outlook.abentry.province'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>
    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="postalCode"
        [text]="'outlook.abentry.postalCode' | translate"
      ></kendo-label>
      <kendo-textbox
        [maxlength]="80"
        #postalCode
        formControlName="postalCode"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.postalCode.errors"
        [fieldTranslation]="'outlook.abentry.postalCode'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>

    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="country"
        [text]="'outlook.abentry.country' | translate"
      ></kendo-label>

      <kendo-dropdownlist
        #country
        [data]="countries"
        formControlName="country"
        textField="name"
        valueField="name"
        [valuePrimitive]="true"
        [defaultItem]="{
          name: 'outlook.abentry.country-placeholder' | translate,
        }"
        [virtual]="{ itemHeight: 40 }"
      >
        <ng-template kendoDropDownListValueTemplate let-data class="flex">
          <img
            *ngIf="data?.flag"
            class="h-4 mr-2 pt-1 mt-1"
            [src]="data?.flag ? cdn + '/assets/images/' + data.flag : ''"
            [alt]="data?.name + ' flag'"
            title="flag"
          />
          <span class="overflow-hidden whitespace-nowrap text-ellipsis">
            {{ data?.name }}
          </span>
        </ng-template>
        <ng-template kendoDropDownListItemTemplate let-data>
          <div class="flex items-center">
            <img
              class="h-4 mx-2"
              *ngIf="data?.flag"
              [src]="data?.flag ? cdn + '/assets/images/' + data.flag : ''"
              [alt]="data?.name + ' flag'"
              title="flag"
            />
            <div class="overflow-hidden whitespace-nowrap text-ellipsis">
              {{ data?.name }}
            </div>
          </div>
        </ng-template>
      </kendo-dropdownlist>
    </kendo-formfield>

    <!-- Template for emails/phones -->
    <ng-template #templateRef let-data let-i="index" let-prop="prop">
      <div class="mt-2 flex flex-row">
        <kendo-formfield class="min-w-[90px] mr-2">
          <kendo-combobox
            [data]="prop.includes('email') ? emailOptions : phoneOptions"
            [formControlName]="prop + 'Description'"
            (ngModelChange)="onEntryChange($event, prop + 'Description', i)"
            [allowCustom]="true"
            [clearButton]="false"
          >
          </kendo-combobox>

          <maximizer-outlook-form-error
            [type]="prop.includes('email') ? 'email' : 'normal'"
            [errorObject]="
              form.group.controls[prop.toString() + 'Description'].errors
            "
            [fieldTranslation]="
              'outlook.abentry.' +
              (prop.includes('email') ? 'email' : 'phone') +
              'Description'
            "
          ></maximizer-outlook-form-error>
        </kendo-formfield>

        <kendo-formfield class="w-full">
          <kendo-textbox
            [maxlength]="prop.includes('email') ? 256 : 22"
            [formControlName]="prop"
            (ngModelChange)="onEntryChange($event, prop, i)"
            [ngClass]="
              i === 0 && prop.includes('email') ? 'text-neutral-60' : ''
            "
            [readonly]="i === 0 && prop.includes('email')"
          ></kendo-textbox>
          <maximizer-outlook-form-error
            [type]="prop.includes('email') ? 'email' : 'normal'"
            [errorObject]="form.group.controls[prop].errors"
            [fieldTranslation]="
              'outlook.abentry.' + (prop.includes('email') ? 'email' : 'phone')
            "
          ></maximizer-outlook-form-error>
        </kendo-formfield>
        <button
          *ngIf="i > 0"
          kendoButton
          rounded="large"
          class="pr-0"
          fillMode="clear"
          themeColor="secondary"
          (click)="onRemoveEntry(i, prop)"
        >
          <em
            class="fa-icons icon-solid icon-12 icon-trash-can font-bold text-black hover:text-neutral-70"
          ></em>
        </button>
      </div>

      <button
        *ngIf="i >= 0 && i === data.length - 1"
        #add
        kendoButton
        class="mt-2 ml-auto p-0"
        fillMode="clear"
        (click)="onAddEntry(prop)"
        [ngClass]="
          i + 1 === getFieldLimit(prop) ? 'text-neutral-60' : 'text-inserv-100'
        "
        [disabled]="i + 1 === getFieldLimit(prop)"
      >
        <em class="fa-icons icon-solid icon-12 icon-plus font-bold"></em>
        <span class="mx-2 text-xs hover:underline">{{
          'outlook.abentry.add-' + (prop.includes('email') ? 'email' : 'phone')
            | translate
        }}</span>
      </button>
    </ng-template>
  </form>

  <div
    class="z-50 flex space-x-2 p-2 w-full bottom-0 sticky bg-neutral-5 mt-auto"
  >
    <button
      kendoButton
      id="outlook-abEntry-ui-addAbEntry-save"
      class="ml-auto uppercase rounded"
      themeColor="primary"
      [disabled]="!canSave"
      (click)="save()"
    >
      <span>{{ 'outlook.buttons.save' | translate }} </span>
      <ng-template #loader *ngIf="loading">
        <kendo-loader
          type="pulsing"
          themeColor="primary"
          size="small"
        ></kendo-loader>
      </ng-template>
    </button>
    <button
      kendoButton
      id="outlook-abEntry-ui-addAbEntry-cancel"
      rounded="large"
      class="uppercase rounded"
      themeColor="primary"
      (click)="cancel()"
      [disabled]="loading"
      fillMode="flat"
    >
      {{ 'outlook.buttons.cancel' | translate }}
    </button>
  </div>
</div>

<maximizer-outlook-notification
  #notification
  [closable]="true"
  [entryId]="type"
>
</maximizer-outlook-notification>

<maximizer-outlook-notification
  #companyNotification
  [closable]="true"
  [entryId]="'company'"
>
</maximizer-outlook-notification>
