<maximizer-outlook-menu
  [pageTitle]="'outlook.main.title' | translate"
  [hideBack]="true"
  [forceWindowRefresh]="true"
></maximizer-outlook-menu>

<div class="h-full w-full flex flex-col bg-neutral-10 main-page scrollbar">
  <span
    kendoTooltip
    filter="[title]"
    [callout]="false"
    [showAfter]="500"
    kendoTooltipWidth="auto"
    [tooltipTemplate]="tooltipTemplate"
  >
    <!-- Not In Maximizer -->
    <div class="m-2 bg-white rounded-sm py-3">
      <span class="subtitle-2 mb-2 pl-3">{{
        'outlook.main.not-in-maximizer'
          | translate: { count: notInMaximizer.length }
      }}</span>

      @if (saveEmailLoading) {
        <maximizer-loader type="legacy"></maximizer-loader>
      }

      @if (showSkeleton) {
        <div class="space-x-2 flex my-2 px-3" *ngFor="let test of [0, 1, 2, 3]">
          <maximizer-outlook-skeleton
            containerClasses="h-9 w-9 max-w-9 rounded-full bg-neutral-20"
            fullWidth="none"
          ></maximizer-outlook-skeleton>
          <maximizer-outlook-skeleton
            containerClasses="h-9 w-full rounded-md bg-neutral-20"
          ></maximizer-outlook-skeleton>
        </div>
      } @else {
        <kendo-listview
          [kendoListViewBinding]="notInMaximizer"
          [pageable]="pagerSettingsNotInMax"
          [pageSize]="pageSize"
          class="border-none"
        >
          <ng-template kendoListViewItemTemplate let-email="dataItem">
            <div class="my-1 rounded min-h-10 flex items-center pl-3 pr-1">
              <div #firstColumn class="flex">
                <div
                  class="bg-neutral-20 rounded-sm w-8 h-8 flex items-center justify-center"
                >
                  <span
                    class="fa-icons icon-solid icon-16 icon-user text-max-white"
                  ></span>
                </div>
              </div>
              <div
                #secondColumn
                class="flex flex-col space-y-0.5 ml-2 my-auto overflow-hidden text-ellipsis"
              >
                <span class="text-body overflow-hidden text-ellipsis">{{
                  email.displayName
                }}</span>
                <span
                  class="caption text-neutral-70 overflow-hidden text-ellipsis"
                  >{{ email.emailAddress }}</span
                >
              </div>
              <div #thirdColumn class="flex flex-col ml-auto">
                <kendo-dropdownbutton
                  id="outlook-main-ui-read-createEntry-{{ email.emailAddress }}"
                  [title]="'outlook.main.tooltip.add-entry' | translate"
                  [data]="addMenuItems"
                  fillMode="flat"
                  [buttonClass]="['hover:bg-inserv-5', 'rounded-sm']"
                  class="my-auto"
                  (itemClick)="
                    navigateToAdd($event, email.emailAddress, email.displayName)
                  "
                  [popupSettings]="{ popupClass: 'bg-inserv-100 mr-2' }"
                >
                  <span
                    class="fa-icons icon-solid icon-18 icon-plus text-inserv-100"
                  ></span>
                  <ng-template kendoDropDownButtonItemTemplate let-dataItem>
                    <div
                      id="outlook-main-ui-read-add{{
                        dataItem.id | titlecase
                      }}-{{ email.emailAddress }}"
                      class="flex items-center gap-1 w-full"
                      kendoTooltip
                      [tooltipTemplate]="tooltipTemplate"
                      position="top"
                      [callout]="false"
                      [showOn]="dataItem.disabled ? 'hover' : 'none'"
                      [title]="
                        'outlook.rights.defaultMessage'
                          | translate
                            : {
                                right: 'outlook.rights.create' | translate,
                                entry: dataItem.title | translate,
                              }
                      "
                    >
                      <span [ngClass]="dataItem.icon" class="w-6"></span>
                      <span>{{
                        'outlook.main.actions.add-' + dataItem.id | translate
                      }}</span>
                    </div>
                  </ng-template>
                </kendo-dropdownbutton>
              </div>
            </div>
          </ng-template>
        </kendo-listview>
      }
    </div>

    <!-- In Maximizer -->
    <div class="m-2 bg-white rounded-sm py-3">
      <span class="subtitle-2 mb-2 pl-3">{{
        'outlook.main.in-maximizer' | translate: { count: inMaximizer.length }
      }}</span>

      @if (showSkeleton) {
        <div class="space-x-2 flex my-2 px-3" *ngFor="let test of [0, 1, 2, 3]">
          <maximizer-outlook-skeleton
            containerClasses="h-9 w-9 max-w-9 rounded-full bg-neutral-20"
            fullWidth="none"
          ></maximizer-outlook-skeleton>
          <maximizer-outlook-skeleton
            containerClasses="h-9 w-full rounded-md bg-neutral-20"
          ></maximizer-outlook-skeleton>
        </div>
      } @else {
        <kendo-listview
          [data]="inMaximizerDataResult"
          (pageChange)="inMaximizerPageChange($event)"
          [pageable]="pagerSettingsInMax"
          [pageSize]="state.take!"
          [skip]="state.skip!"
          class="border-none mt-1"
        >
          <ng-template kendoListViewItemTemplate let-dataItem="dataItem">
            <ng-container
              *ngTemplateOutlet="
                itemTemplate;
                context: {
                  $implicit: dataItem,
                  canNavigate: true,
                  saveForMainCard: true,
                }
              "
            ></ng-container
          ></ng-template>
        </kendo-listview>

        @if (inMaximizer.length > 1) {
          <div class="w-full flex justify-end">
            <span
              kendoTooltip
              [tooltipTemplate]="tooltipTemplate"
              position="top"
              [callout]="false"
              [title]="
                getInMaximizerPermission()
                  ? disableSaveEmailAllEntries
                    ? ('outlook.main.tooltip.email-saved-all' | translate)
                    : ('outlook.main.tooltip.save-email-all' | translate)
                  : getSaveEmailPermissionMessage(undefined, 'default', true)
              "
            >
              <button
                kendoButton
                id="outlook-main-ui-read-saveAllEntries"
                (click)="saveEmailForAllEntries()"
                [disabled]="
                  disableSaveEmailAllEntries || !getInMaximizerPermission()
                "
                fillMode="flat"
                class="ml-auto hover:bg-inserv-5 mt-1 mr-3 text-inserv-100 rounded text-inline-button"
              >
                {{ 'outlook.main.save-to-all' | translate }}
              </button>
            </span>
          </div>
        }
      }
    </div>
  </span>

  <!-- Related Opportunity/Case -->
  @if (
    (hasAbEntries && userRights?.opportunities?.read) ||
    (hasAbEntries && userRights?.customerService?.read)
  ) {
    @if (loadingRelated) {
      <maximizer-outlook-skeleton
        containerClasses="h-10 w-full rounded-md bg-neutral-20"
        fullWidth="full"
      ></maximizer-outlook-skeleton>
    } @else {
      <div
        id="outlook-main-ui-read-related"
        class="mx-2 mb-2 bg-white rounded-sm p-3 flex justify-between items-center"
        [ngClass]="{
          'hover:cursor-pointer':
            this.related.opportunity.hasEntries || this.related.case.hasEntries,
        }"
        (click)="
          (this.related.opportunity.hasEntries ||
            this.related.case.hasEntries) &&
            navigateToRelated()
        "
      >
        <div class="flex flex-col">
          <p class="subtitle-2">
            {{ relatedTitle }}
          </p>
          @if (
            this.related.opportunity.emailCount > 0 ||
            this.related.case.emailCount > 0
          ) {
            <p class="subtitle-2 text-neutral-80 pt-1 line-clamp-2">
              {{ relatedSavedEmailMessage }}
            </p>
          }
        </div>

        <span
          class="fa-icons icon-14 icon-chevron-right mx-2"
          [ngClass]="{
            'text-neutral-40':
              !this.related.opportunity.hasEntries &&
              !this.related.case.hasEntries,
          }"
        ></span>
      </div>
    }
  }

  <!-- Save to Entry Search -->
  <div class="mx-2 bg-white rounded-sm py-3">
    <div class="px-3">
      <p class="subtitle-2">
        {{
          'outlook.main.save-to-other'
            | translate: { count: otherInMaximizer.length }
        }}
      </p>

      <kendo-formfield #anchor>
        <kendo-textbox
          id="outlook-main-ui-read-search"
          [title]="''"
          [(ngModel)]="autocompleteInput"
          (valueChange)="handleAutocompleteValueChange($event)"
          #searchInMaximizer
          valueField="name"
          [placeholder]="'outlook.forms.searchInMaximizer' | translate"
          class="my-1"
        >
        </kendo-textbox>
      </kendo-formfield>
    </div>
    <div
      kendoTooltip
      filter="[title]"
      [callout]="false"
      [showAfter]="500"
      kendoTooltipWidth="auto"
      [tooltipTemplate]="tooltipTemplate"
    >
      @if (!showSkeleton) {
        <kendo-listview
          [kendoListViewBinding]="otherInMaximizer"
          class="border-none"
        >
          <ng-template kendoListViewItemTemplate let-dataItem="dataItem">
            <ng-container
              *ngTemplateOutlet="
                itemTemplate;
                context: {
                  $implicit: dataItem,
                  canNavigate: true,
                  saveForMainCard: false,
                }
              "
            ></ng-container
          ></ng-template>
        </kendo-listview>
      }

      <kendo-popup
        #popup
        [anchor]="searchInMaximizer?.input?.nativeElement"
        (anchorViewportLeave)="handleLeavePopup()"
        class="w-full px-5 rounded-sm"
        *ngIf="showPopup"
        containerClass="my-2"
      >
        <div class="h-auto w-full rounded-sm flex my-1">
          <maximizer-loader
            *ngIf="saveEmailFromPopupLoading"
            type="legacy"
            class="small"
          ></maximizer-loader>

          <div
            class="h-10 mt-auto w-full flex space-x-2 px-2"
            *ngIf="showAutocompleteSkeleton"
          >
            <maximizer-outlook-skeleton
              containerClasses="h-9 w-9 max-w-9 rounded-full bg-neutral-20"
              fullWidth="none"
            ></maximizer-outlook-skeleton>
            <maximizer-outlook-skeleton
              containerClasses="h-9 w-full rounded-md bg-neutral-20"
            ></maximizer-outlook-skeleton>
          </div>
          <span
            *ngIf="!showAutocompleteSkeleton && popupEntryList.length === 0"
            class="m-auto"
            >No data found</span
          >
          <div
            *ngIf="!showAutocompleteSkeleton && popupEntryList.length > 0"
            class="w-full"
          >
            <kendo-listview
              [kendoListViewBinding]="popupEntryList"
              class="main-page border-none overflow-y-auto max-h-[160px]"
              *ngIf="!showSkeleton"
            >
              <ng-template kendoListViewItemTemplate let-dataItem="dataItem">
                <ng-container
                  *ngTemplateOutlet="
                    itemTemplate;
                    context: {
                      $implicit: dataItem,
                      canNavigate: false,
                      saveForMainCard: false,
                    }
                  "
                ></ng-container
              ></ng-template>
            </kendo-listview>
          </div>
        </div>
      </kendo-popup>
    </div>
  </div>
</div>

<ng-template
  #itemTemplate
  let-dataItem
  let-canNavigate="canNavigate"
  let-saveForMainCard="saveForMainCard"
>
  <div
    id="outlook-main-ui-read-viewEntry-{{ dataItem.tag }}"
    class="mb-1 rounded min-h-10 flex pr-1 pl-3 py-1 items-center"
    [ngClass]="canNavigate ? 'hover:bg-inserv-10 hover:cursor-pointer' : ''"
    [attr.title]="
      !canNavigate
        ? null
        : ('outlook.main.tooltip.view-entry'
          | translate
            : {
                type:
                  dataItem.type === 'abentry'
                    ? dataItem.abentryType
                    : dataItem.type,
              })
    "
  >
    <div
      class="flex-none w-8 my-auto"
      (click)="canNavigate && navigateToEntry(dataItem.id, dataItem.type)"
    >
      <div
        class="avatar avatar-sm avatar-neutral-30 avatar-border"
        [ngClass]="
          dataItem.abentryType === 'individual' ||
          dataItem.abentryType === 'company'
            ? 'avatar-square'
            : ''
        "
      >
        <span
          class="font-bold"
          *ngIf="
            dataItem.type === 'abentry' && dataItem.abentryType !== 'company'
          "
          >{{
            dataItem.displayName ?? dataItem.emailAddress | nameInitials
          }}</span
        >

        <em
          *ngIf="dataItem.type === 'lead'"
          class="fa-icons icon icon-solid icon-12 icon-user-magnifying-glass m-auto"
        ></em>
        <em
          *ngIf="dataItem.abentryType === 'company'"
          class="fa-icons icon icon-regular icon-16 icon-building m-auto"
        ></em>
      </div>
    </div>
    <div
      class="flex-grow bg-gray-300 overflow-hidden text-ellipsis flex flex-col space-y-0.5 ml-2 my-auto"
      (click)="canNavigate && navigateToEntry(dataItem.id, dataItem.type)"
    >
      <div class="flex">
        <span class="text-body overflow-hidden text-ellipsis">{{
          dataItem.displayName
        }}</span>
        <div
          *ngIf="dataItem.isNewEntry"
          class="bg-finserv-10 caption rounded-md w-7 h-4 ml-2 flex"
        >
          <span class="m-auto">{{ 'outlook.main.new' | translate }}</span>
        </div>
      </div>
      <span class="caption text-neutral-70 overflow-hidden text-ellipsis">{{
        dataItem.emailAddress
      }}</span>
      <span class="caption text-neutral-70 break-all">{{
        (dataItem.type === 'lead'
          ? 'outlook.entries.lead'
          : 'outlook.entries.' + dataItem.abentryType
        ) | translate
      }}</span>
    </div>

    <div
      kendoTooltip
      [callout]="false"
      [tooltipTemplate]="tooltipTemplate"
      [title]="
        dataItem.emailSaved
          ? ('outlook.main.tooltip.email-saved' | translate)
          : getSaveEmailPermission(dataItem.type)
            ? ('outlook.main.tooltip.save-email' | translate)
            : getSaveEmailPermissionMessage(dataItem.type, 'default', false)
      "
    >
      <button
        kendoButton
        id="outlook-main-ui-read-saveEmail-{{ dataItem.tag }}"
        fillMode="flat"
        [disabled]="
          dataItem.emailSaved || !getSaveEmailPermission(dataItem.type)
        "
        (click)="
          saveForMainCard
            ? saveEmailForEntry(dataItem)
            : saveEmailFromPopup(dataItem)
        "
        [iconClass]="
          dataItem.emailSaved
            ? 'maximizer-icons icon-saved-email text-neutral-40 icon-20'
            : getSaveEmailPermission(dataItem.type)
              ? 'maximizer-icons icon-save-email text-inserv-100 icon-24'
              : 'maximizer-icons icon-save-email text-neutral-40 icon-24'
        "
      ></button>
    </div>
  </div>
</ng-template>

<ng-template #tooltipTemplate let-anchor>
  <p class="caption text-center text-neutral-90 whitespace-nowrap">
    {{ anchor.nativeElement.getAttribute('data-title') }}
  </p>
</ng-template>

<maximizer-outlook-notification
  entryId="entries"
  #notification
  [closable]="true"
>
</maximizer-outlook-notification>
